import React, { useEffect } from "react"

import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import HubSpotForm from "../components/HubSpotForm"

import HeaderImage from "../assets/img/new/tlo-serch-01.webp"

import { devMode } from "../components/Variables"

import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const SeoConsultation = () => {
  const { t } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage("de")
  }, [])

  return (
    <Layout
      seo={{
        title: "Kostenlose SEO-Beratung • Füllen Sie das Formular aus • WeNet.pl",
        description:
          "Nutzen Sie eine kostenlose SEO-Beratung und steigern Sie die Position Ihrer Webseite in Google • Füllen Sie das Anfrageformular aus",
        hreflangs: {
          pl: "bezplatna-konsultacja-seo/",
          en: "en/free-seo-consultation/",
          de: "de/kostenlose-seo-beratung/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Heim",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/de/kostenlose-seo-beratung/",
                name: t("seo-title-free-seo-consultation"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-free-seo-consultation"),
                text: t("link-text-free-seo-consultation"),
              },
            ]}
          />
        </div>
        <h1 className="title text-center">Kostenlose SEO-Beratung</h1>
      </div>

      <section className="standard-section contact-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-10 offset-md-1 offset-lg-0 col-12">
              <p className="text-blue font-18" style={{ marginTop: "30px" }}>
                Möchten Sie Fehler auf Ihrer Webseite korrigieren und ihre Position unter den Google-Ergebnissen
                verbessern? Füllen Sie das Formular aus. Unser Berater wird sich mit Ihnen in Verbindung setzen und Ihre
                Webseite kostenlos prüfen.
                <br />
                <br />
              </p>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <h2>Anfrageformular</h2>
              <HubSpotForm
                formId={
                  devMode === "true" ? "56aff95b-5b7b-43ac-a859-dd7f82f71a64" : "6f44bfc8-932d-4ef7-89cd-f350e8d87b49"
                }
                formType="consultation"
              />
            </div>
            <div className="col-xl-4 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <p className="text-blue font-18" style={{ marginTop: "33px" }}>
                Mit einer SEO-Beratung erhalten Sie Hinweise für die Optimierung. Wenn Sie Korrekturen einführen,
                erhalten Sie:
                <br />
                <br />
              </p>
              <ul style={{ marginBottom: "45px" }}>
                <li>
                  Eine optimierte Seite, die nicht schon im Voraus an verlorener Position steht (in einigen Fällen
                  können Fehler die Seite vollständig aus dem Spiel eliminieren).
                </li>
                <li>
                  Zeit- und Geldersparnis - eine korrekte, qualitativ hochwertige Webseite ist eine Garantie für eine
                  maximale Kostenreduzierung bei weiteren SEO- und SEM-Aktivitäten,
                </li>
                <li>
                  Eine höhere Position der Webseite und mehr Verkehr - eine optimierte Webseite bedeutet eine schnellere
                  Indexierung durch Google-Roboter,
                </li>
                <li>
                  Effektiven Zugang zu den Benutzern, d.h. potenziellen Kunden - von einer wertvollen Webseite können
                  auch die Benutzer profitieren, da sie schneller und effektiver die gesuchten Informationen finden
                  können.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SeoConsultation
